@font-face {
  font-family: 'Libre Baskerville', sans-serif;
  src:
    local('Libre Baskerville'),
    url(./assets/fonts/LibreBaskerville-Regular.ttf) format('truetype');
  font-weight: 400; /* Specify the font weight */
  font-style: normal; /* Specify the font style */
  font-display: swap;
}

@font-face {
  font-family: 'Roboto', sans-serif;
  src:
    local('Roboto'),
    url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
  font-weight: 400; /* Specify the font weight */
  font-style: normal; /* Specify the font style */
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  src:
    local('Montserrat'),
    url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
  font-weight: 400; /* Specify the font weight */
  font-style: normal; /* Specify the font style */
  font-display: swap;
}

@font-face {
  font-family: 'Oxygen', sans-serif;
  src:
    local('Oxygen'),
    url(./assets/fonts/Oxygen-Regular.ttf) format('truetype');
  font-weight: 400; /* Specify the font weight */
  font-style: normal; /* Specify the font style */
  font-display: swap;
}
